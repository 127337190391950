import React, { Component } from 'react';

export default class TextError extends Component {
    render() {
        return (
            <>
              <span style={{ color: 'red' }}>&nbsp; * {this.props.text}</span>
            </>
          );
    }
}