require('dotenv').config();
export default class Vacancies {
    
    constructor() {}

    list = async (params = {}) => {        
        params.page = parseInt(params.page) || 1;
        params.limit = params.limit || 10;
        params.search = params.search || '';

        const { page, limit, search } = params;
        const token = window.localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/vacancies/list?page=${page}&limit=${limit}&search=${search}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(res => {
                if (res.status === 401) {
                    window.location.href = '/';
                }
                res.json()
                .then(data => {
                    if(data.status && data.status.code === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        });
    }
    create = async (payload) => {
        const token = window.localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/vacancies/create`, {
                method: 'POST', 
                headers: {                    
                    'Authorization': `Bearer ${token}`
                },                
                body: payload
            })
            .then(res => {
                if (res.status === 401) {
                    window.location.href = '/';
                }
                res.json()
                .then(data => {
                    if(data.code === 201) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        });
    }
    update = async (admissionID, payload) => {
        const token = window.localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/vacancies/${admissionID}`, {
                method: 'PUT',
                headers: {                    
                    'Authorization': `Bearer ${token}`
                }, 
                body: payload
            })
            .then(res => {
                if (res.status === 401) {
                    window.location.href = '/';
                }
                res.json()
                .then(data => {
                    if(data.status.code === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                console.log(err);
                reject(err);
            });
        });
    }

    delete = async (payload) => {
        const token = window.localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/vacancies/${payload.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload)
            })
            .then(res => {
                if (res.status === 401) {
                    window.location.href = '/';
                }
                res.json()
                .then(data => {
                    if(data.status.code === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                console.error('catch: ', err);
                reject(err);
            });
        });
    }
}