import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Modal, Loading } from '../../../components';
import { Categories } from '../../../services';
import { MDBDataTable } from 'mdbreact';
import { CirclePicker } from 'react-color';
import { FormattedMessage } from "react-intl";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-toastify/dist/ReactToastify.css';
require('dotenv').config();

const categories = new Categories();

export default function Category(props) {    
    const [mode, setMode] = useState('Add');
    const [loading, setLoading] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [payload, setPayload] = useState({
        id: '',
        name: '',
        color: '',
        created_by: 'ADMIN',
        updated_by: 'ADMIN',
        old_image: '',
        files: []
    });

    useEffect(() => {
        (async () => getCategoryList())();
    }, []);
    
    const getCategoryList = () => {
        setLoading(true);

        categories.list({ limit: 100 })
        .then(res => {
            console.log(res);

            res.result.map((cat, i) => {
                res.result[i].no = i + 1;
                res.result[i].color_display = <span class="badge badge-secondary" style={{ color: cat.color, backgroundColor: cat.color }}>text</span>
                res.result[i].option = 
                    <>
                        <button 
                            className="btn btn-warning btn-sm mr-1" 
                            data-toggle="modal" 
                            data-target="#addModal" 
                            onClick={() => getCategoryInfo(cat)}> 
                                <i className="lnr lnr-pencil"></i> 
                                <FormattedMessage id="component.edit.button"/>
                        </button>
                        <button 
                            className="btn btn-danger btn-sm" 
                            data-toggle="modal" 
                            data-target="#deleteModal"
                            onClick={() => getCategoryInfo(cat)}> 
                                <i className="lnr lnr-trash"></i>
                                <FormattedMessage id="component.delete.button"/>
                        </button>                       
                    </>
            });
        
            setLoading(false);
            setCategoryList(res.result);
        })
        .catch(err => {
            console.error(err);
            
            setLoading(false);
            setCategoryList([]);
        })
    }

    const getCategoryInfo = (category) => {      
        payload.id = category.id;
        payload.name = category.name; 
        payload.color = category.color;      
        
        setPayload({ ...payload });
        setMode('Edit');
    }

    const createCategory = () => {        
        setLoading(true);

        categories.create(payload)
        .then(res => {
            console.log(res);
            if (res.code === 201) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.add-successful'/>);

                _handleResetPayload();
                getCategoryList();
            }
        })
        .catch(err => {
            console.error(err);
            toast.error(<div>{<FormattedMessage id='component.text.add-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)

            setLoading(false);
        })
    }

    const updateCategory = () => {        
        setLoading(true);

        categories.update(payload)
        .then(res => {
            console.log(res);
            if (res.status.code === 200) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.edit-successful'/>);

                _handleResetPayload();
                getCategoryList();
            }
        })
        .catch(err => {
            console.error(err);
            toast.error(<div>{<FormattedMessage id='component.text.edit-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)

            setLoading(false);
        })
    }

    const deleteCategory = () => {
        setLoading(true);

        categories.delete(payload)
        .then(res => {
            console.log(res);
            if (res.status.code === 200) {
                document.getElementById('deleteModal').click();
                toast.success(<FormattedMessage id='component.text.delete-successful'/>);

                _handleResetPayload();
                getCategoryList();
            }
        })
        .catch(err => {
            console.error(err);
            toast.error(<div>{<FormattedMessage id='component.text.delete-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)

            setLoading(false);
        })
    }
    
    const _renderModalTitle = mode => {
        switch(mode) {
            case 'Add':
                return <FormattedMessage id="component.add.button"/>;
            case 'Edit':
                return <FormattedMessage id="component.edit.button"/>
            default:
                return 'Import'
        }
    }
    
    const _renderModalSubmit = mode => {
        switch(mode) {
            case 'Add':
                createCategory()
                break;
            case 'Edit':
                updateCategory()
                break;
            default:
                break;
        }
    }
    const _handlePayload = (val, state) => {         
        setPayload({
            ...payload,
            [state]: val
        });

    }

    const _handleResetPayload = () => {
        setPayload({
            ...payload,
            id: '',
            name: '',           
        });
    }
    

    const data = {
        columns: [
          {
            label: <FormattedMessage id='form.number' defaultMessage='No'/>,
            field: 'no',
            sort: 'asc',
            width: 150
          },
          {
            label: <FormattedMessage id='form.category.name'/>,
            field: 'name',
            sort: 'asc',
            width: 270
          },
          {
            label: <FormattedMessage id='form.category.color'/>,
            field: 'color_display',
            sort: 'asc',
            width: 200
          },
          {
            label: <FormattedMessage id='form.options'/>,
            field: 'option',
            sort: 'asc',
            width: 200
          },     
        ],
        rows: categoryList || []
    };

    return (
        <div>
             <div className="main">

                <ToastContainer
                    autoClose={5000}
                    hideProgressBar
                    pauseOnHover={false} />
                <div className="main-content">
                    <div className="container-fluid">
                        <h3 className="page-title">
                            <FormattedMessage id="menu.category"/>
                        </h3>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-header mt-2 mr-2">                                    
                                        <div className="row">
                                            <div className="col-md-6"></div>
                                            <div className="col-md-6">  
                                                <button 
                                                    className="btn btn-primary" 
                                                    data-toggle="modal" 
                                                    data-target="#addModal" 
                                                    style={{float:'right'}}
                                                    onClick={() => setMode('Add')}
                                                ><FormattedMessage id="component.add.button"/></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel-body">    
                                        <Loading visible={loading}/>                                    
                                        <MDBDataTable
                                            className="table mt-5"
                                            data={data}
                                            sortable
                                            noBottomColumns    
                                            entriesOptions={[5, 10, 20, 50]}
                                            displayEntries={true}
                                            paging={true}
                                            noRecordsFoundLabel={<FormattedMessage id="component.text.no-records"/>}                                                                                                                
                                        />
                                    </div>
                                </div>
                                {/* Modal Add */}
                                <form onSubmit={e => {e.preventDefault(); _renderModalSubmit(mode)}}>  
                                    <Modal
                                        title={_renderModalTitle(mode)}
                                        label={mode}
                                        form={
                                            <>
                                                <div className="form-group">
                                                    <label><FormattedMessage id="form.category.name"/></label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control"                                                    
                                                        onChange={e => _handlePayload(e.target.value, 'name')}
                                                        value={payload.name}
                                                        required/>
                                                </div>                                                                                                                                
                                                <div className="form-group">
                                                    <p style={{fontWeight:'bold'}}><FormattedMessage id="form.category.color-picker"/></p>
                                                    <CirclePicker onChange={e => _handlePayload(e.hex, 'color')} />
                                                </div>
                                            </>
                                        }

                                        onClose={() => _handleResetPayload()}
                                        onDelete={() => deleteCategory()}
                                        disabled={loading}
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
