import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Modal, Loading, RequiredSelect, TextError } from '../../../components';
import Constants from '../../../components/Constants';
import { Academics } from '../../../services';
import { MDBDataTable } from 'mdbreact';
import { FormattedMessage } from "react-intl";
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from "react-draft-wysiwyg";
import ImageUploader from 'react-images-upload';
import BaseSelect from 'react-select';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-toastify/dist/ReactToastify.css';
require('dotenv').config();

const academic = new Academics();
const Select = props => (
    <RequiredSelect
        {...props}
        SelectComponent={BaseSelect}
        options={props.options}
    />
);

export default function Academic(props) {    
    const [mode, setMode] = useState('Add');
    const [loading, setLoading] = useState(false);
    const [editorState, setEditorState] = useState('');
    const [enEditorState, setEnEditorState] = useState('');
    const [academicList, setAcademicList] = useState([]);
    const [payload, setPayload] = useState({
        id: '',
        title: '',
        content: '',
        status: false,
        level: '',
        created_by: 'ADMIN',
        updated_by: 'ADMIN',
        old_image: '',
        files: [],
        en_content: '',
        en_title: ''

    });

    useEffect(() => {
        (async () => getAcademicList())();
    }, []);
    
    const getAcademicList = () => {
        setLoading(true)

        academic.list({ limit: 100 })
        .then(res => {
            console.log(res);    
            
            res.result.map((academic, i) => {
                res.result[i].no = i + 1; 
                res.result[i].level = _renderLevel(academic.level);
                res.result[i].display_image = 
                    <img 
                        src={getImage(academic.image)}
                        className="img-fluid" 
                        style={{width:'150px'}} 
                        title={academic.image} 
                        alt={academic.image}
                    />
                res.result[i].option = 
                    <>
                        <button 
                            className="btn btn-warning btn-sm mr-1" 
                            data-toggle="modal" 
                            data-target="#addModal" 
                            onClick={() => getAcademicInfo(academic)}> 
                                <i className="lnr lnr-pencil"></i> 
                                <FormattedMessage id="component.edit.button"/>
                        </button>
                        <button 
                            className="btn btn-danger btn-sm" 
                            data-toggle="modal" 
                            data-target="#deleteModal"
                            onClick={() => getAcademicInfo(academic)}> 
                                <i className="lnr lnr-trash"></i>
                                <FormattedMessage id="component.delete.button"/>
                        </button>                       
                    </>
            });
            
            setLoading(false)
            setAcademicList(res.result);
        })
        .catch(err => {
            console.error(err);

            setLoading(false)        
            setAcademicList([]);
        })
    }

    const getAcademicInfo = (academic) => {      
        payload.id = academic.id;
        payload.title = academic.title;
        payload.content =  academic.content;
        payload.status = academic.status;
        payload.level = academic.level;
        payload.old_image = academic.image;     
        payload.en_content = academic.en_content;
        payload.en_title = academic.en_title       
        
        changeEditorState(academic.content);
        changeEnEditorState(academic.en_content);
        setPayload({ ...payload });
        setMode('Edit');
    }

    const changeEditorState = (html) => {
        const es = EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(html)
            )
        );
        setEditorState(es);
    }

    const changeEnEditorState = (html) => {
        const es = EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(html)
            )
        );
        setEnEditorState(es);
    }
    const createAcademic = () => {
        let fd = new FormData();
        for (let key in payload) {
            if (key === 'files') {
                fd.append(key, payload[key][0]);
            } else {
                fd.append(key, payload[key])
            }
        }
        setLoading(true)

        academic.create(fd)
        .then(async res => {
            console.log(res);
            if (res.code === 201) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.add-successful'/>);

                await _handleResetPayload();
                await getAcademicList();
            }
        })
        .catch(err => {
            console.error(err);
            toast.error(<div>{<FormattedMessage id='component.text.add-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)

            setLoading(false)
        })
    }

    const updateAcademic = () => {
        let fd = new FormData();
        for (let key in payload) {
            if (key === 'files') {
                fd.append(key, payload[key][0]);
            } else {
                fd.append(key, payload[key])
            }
        }
        setLoading(true)

        academic.update(payload.id, fd)
        .then(async res => {
            console.log(res);
            if (res.status.code === 200) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.edit-successful'/>);

                await _handleResetPayload();
                await getAcademicList();
            }
        })
        .catch(err => {
            console.error(err);
            toast.error(<div>{<FormattedMessage id='component.text.edit-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)

            setLoading(false)
        })
    }

    const deleteAcademic = () => {
        setLoading(true)

        academic.delete(payload)
        .then(async res => {
            console.log(res);
            if (res.status.code === 200) {
                document.getElementById('deleteModal').click();
                toast.success(<FormattedMessage id='component.text.delete-successful'/>);

                await _handleResetPayload();
                await getAcademicList();
            }
        })
        .catch(err => {
            console.error(err);
            toast.error(<div>{<FormattedMessage id='component.text.delete-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)

            setLoading(false)
        })
    }

    const getImage = (fileName) => {
        return `${process.env.REACT_APP_URL_SERVICE}/public/image/academics/${fileName}`;
    }


    const onEditorStateChange = editorState => {
        setEditorState(editorState);
    }
    const onEnEditorStateChange = editorState => {
        setEnEditorState(editorState);
    }
    
    const _renderModalTitle = mode => {
        switch(mode) {
            case 'Add':
                return <FormattedMessage id="component.add.button"/>;
            case 'Edit':
                return <FormattedMessage id="component.edit.button"/>
            default:
                return 'Import'
        }
    }
    
    const _renderModalSubmit = mode => {
        switch(mode) {
            case 'Add':
                createAcademic()
                break;
            case 'Edit':
                updateAcademic()
                break;
            default:
                break;
        }
    }

    const _renderLevel = level => {
        for(let i in Constants.TYPE.LEVEL) {
            if (level === Constants.TYPE.LEVEL[i].value) {
                return Constants.TYPE.LEVEL[i].label;
            }
        }
    }
    const _handlePayload = (val, state) => {  
        if (state === 'content' || state === 'en_content') {
            val = draftToHtml(val);

        } 
        setPayload({
            ...payload,
            [state]: val
        });

    }

    const _handleResetPayload = () => {
        setPayload({
            ...payload,
            id: '',
            title: '',
            content: '',
            status: false,
            level: '',
            files: [],
            old_image: '',
            en_content: '',
            en_title: ''
        });

        changeEditorState('<p></>');
        changeEnEditorState('<p></p>');
    }
    

    const data = {
        columns: [
          {
            label: <FormattedMessage id='form.number' defaultMessage='No'/>,
            field: 'no',
            sort: 'asc',
            width: 150
          },
          {
            label: <FormattedMessage id='form.article.title'/>,
            field: 'title',
            sort: 'asc',
            width: 270
          },
          {
            label: <FormattedMessage id='form.article.image'/>,
            field: 'display_image',
            sort: 'asc',
            width: 200
          },  
          {
            label: <FormattedMessage id='form.level'/>,
            field: 'level',
            sort: 'asc',
            width: 200
          }, 
          {
            label: <FormattedMessage id='form.options'/>,
            field: 'option',
            sort: 'asc',
            width: 200
          },     
        ],
        rows: academicList || []
    };

    return (
        <div>
             <div className="main">

                <ToastContainer
                    autoClose={5000}
                    hideProgressBar
                    pauseOnHover={false} />
                <div className="main-content">
                    <div className="container-fluid">
                        <h3 className="page-title">
                            <FormattedMessage id="menu.academic"/>
                        </h3>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-header mt-2 mr-2">                                    
                                        <div className="row">
                                            <div className="col-md-6"></div>
                                            <div className="col-md-6">  
                                                <button 
                                                    className="btn btn-primary" 
                                                    data-toggle="modal" 
                                                    data-target="#addModal" 
                                                    style={{float:'right'}}
                                                    onClick={() => setMode('Add')}
                                                ><FormattedMessage id="component.add.button"/></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel-body">    
                                        <Loading visible={loading}/>                                    
                                        <MDBDataTable
                                            className="table mt-5"
                                            data={data}
                                            sortable
                                            noBottomColumns    
                                            entriesOptions={[5, 10, 20, 50]}
                                            displayEntries={true}
                                            paging={true}
                                            noRecordsFoundLabel={<FormattedMessage id="component.text.no-records"/>}                                                                                                                
                                        />
                                    </div>
                                </div>
                                {/* Modal Add */}
                                <form onSubmit={e => {e.preventDefault(); _renderModalSubmit(mode)}}>  
                                    <Modal
                                        title={_renderModalTitle(mode)}
                                        label={mode}
                                        form={
                                            <>
                                                <div className="form-group">
                                                    <label><TextError/><FormattedMessage id="form.vacancies.title"/></label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control"                                                    
                                                        onChange={e => _handlePayload(e.target.value, 'title')}
                                                        value={payload.title}
                                                        required/>
                                                </div>
                                                <div className="form-group">
                                                    <label><TextError/><FormattedMessage id="form.vacancies.en_title"/></label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control"                                                    
                                                        onChange={e => _handlePayload(e.target.value, 'en_title')}
                                                        value={payload.en_title}
                                                        required/>
                                                </div>
                                                <div className="form-group" style={{border:'1px solid #eaeaea',padding:'10px',borderRadius:'5px'}}>
                                                    <label><TextError/><FormattedMessage id="form.vacancies.content"/></label>
                                                    <br/><br/>                                                
                                                    <Editor
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        toolbar={{
                                                            options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'remove', 'history'],
                                                            inline: { inDropdown: true },
                                                            list: { inDropdown: true },
                                                            link: { inDropdown: true },
                                                            history: { inDropdown: true },
                                                        }}
                                                        onContentStateChange={contentState => _handlePayload(contentState, 'content')}
                                                        onEditorStateChange={onEditorStateChange}
                                                        editorState={editorState}
                                                        required                                                    />
                                                </div>
                                                <div className="form-group" style={{border:'1px solid #eaeaea',padding:'10px',borderRadius:'5px'}}>
                                                    <label><TextError/><FormattedMessage id="form.vacancies.en_content"/></label>
                                                    <br/><br/>                                                
                                                    <Editor
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        toolbar={{
                                                            options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'remove', 'history'],
                                                            inline: { inDropdown: true },
                                                            list: { inDropdown: true },
                                                            link: { inDropdown: true },
                                                            history: { inDropdown: true },
                                                        }}
                                                        onContentStateChange={contentState => _handlePayload(contentState, 'en_content')}
                                                        onEditorStateChange={onEnEditorStateChange}
                                                        editorState={enEditorState}
                                                        required                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label><TextError/><FormattedMessage id="form.article.image"/></label>                    
                                                    <br/><br/>
                                                    {payload.old_image && (
                                                        <div style={{
                                                            textAlign: 'center'
                                                        }}>
                                                        
                                                            <img 
                                                                src={getImage(payload.old_image)} 
                                                                width="250"                                                            
                                                            />
                                                        </div>
                                                    )}
                                                    <ImageUploader
                                                        withIcon={true}
                                                        buttonText={
                                                            (payload.files && payload.files.length > 0) || payload.old_image
                                                            ? payload && payload.files.length > 0 && payload.files[0].name || payload.old_image
                                                            : 'Choose an image'
                                                        }
                                                        onChange={pic => _handlePayload([pic[pic.length - 1]], 'files')}
                                                        imgExtension={['.jpg', '.png']}
                                                        maxFileSize={5242880}
                                                        required
                                                    />
                                                </div>                                                                                               

                                                <div className="form-group">
                                                    <Select
                                                        className="mt-2"
                                                        value={
                                                            Constants.TYPE.LEVEL 
                                                            ? Constants.TYPE.LEVEL.find(item => item.value === payload.level)
                                                            : null
                                                        }                        
                                                        placeholder={"Select a level"}           
                                                        options={Constants.TYPE.LEVEL}                                    
                                                        onChange={(e) => {
                                                            _handlePayload(e.value, 'level')
                                                        }}
                                                        // ref={selectInputRef}
                                                        required
                                                    />
                                                </div>
                                            </>
                                        }

                                        // onClick={(e) => {e.preventDefault; _renderModalSubmit(mode)}}
                                        onClose={() => _handleResetPayload()}
                                        onDelete={() => deleteAcademic()}
                                        disabled={loading}
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
