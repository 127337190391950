const TYPE = {
    USER_ROLE: [
        {
            label: 'Admin',
            value: 1
        },
        {
            label: 'Pengguna',
            value: 2
        },
    ],
    LEVEL: [
        {
            label: 'Kindergarten',
            value: 'TK'
        },
        {
            label: 'Elementary School',
            value: 'SD'
        },
        {
            label: 'Secondary School',
            value: 'SMP'
        },
        {
            label: 'High School',
            value: 'SMA'
        }
    ]
};

const LANGUAGES = [
    {
        label: 'Bahasa Indonesia',
        value: 'id-ID'
    },
    {
        label: 'English',
        value: 'en-EN'
    }
];

export default { TYPE, LANGUAGES };
