import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Modal, Constants, Loading, RequiredSelect } from '../../../components';
import { Users } from '../../../services';
import { MDBDataTable } from 'mdbreact';
import { FormattedMessage } from "react-intl";
import BaseSelect from 'react-select';
import 'react-toastify/dist/ReactToastify.css';

const users = new Users();
const Select = props => (
    <RequiredSelect
        {...props}
        SelectComponent={BaseSelect}
        options={props.options}
    />
);

export default function User(props) {
    const selectInputRef = useRef();
    useEffect(() => {
        (async () => getUserList())();
    }, []);

    const [payload, setPayload] = useState({
        id: '',
        username: '',
        password: '',
        status: true,
        level: 1,
        created_by: 'ADMIN',
        updated_by: 'ADMIN'
    });    
    const [userList, setUserList] = useState([]);
    const [mode, setMode] = useState('Add');
    const [loading, setLoading] = useState(false);

    const getUserList = () => {
        setLoading(true);
        users.list({ limit: 100 })
        .then(res => {
            console.log(res);

            res.result.map( (item, i) => {
                const role = Constants.USER_ROLE.filter(role => role.value === item.level );

                res.result[i].no = i + 1;   
                res.result[i].level_text = role[0].label;
                res.result[i].option = 
                    <>
                        <button 
                            className="btn btn-warning btn-sm mr-1" 
                            data-toggle="modal" 
                            data-target="#addModal" 
                            onClick={() => getUserInfo(item)}> 
                                <i className="lnr lnr-pencil"></i>
                                <FormattedMessage id="component.edit.button"/>
                        </button>
                        <button 
                            className="btn btn-danger btn-sm" 
                            data-toggle="modal" 
                            data-target="#deleteModal"
                            onClick={() => getUserInfo(item)}> 
                                <i className="lnr lnr-trash"></i> 
                                <FormattedMessage id="component.delete.button"/>
                        </button>
                    </>
            })

            setUserList(res.result);
            setLoading(false);
        })
        .catch(err => {
            console.log(err);

            if (err.code === 404) {
                setUserList([]);
            }
            setLoading(false);
        });
    };

    const handlePayload = (e, state) => {
        if (!e) {
            return false;
        }
        setPayload({
            ...payload,
            [state]: state === 'level' ? e.value : e.target.value
        });
    }

    const _renderModalSubmit = mode => {
        switch(mode) {
            case 'Add':
                createUser()
                break;
            case 'Edit':
                updateUser()
                break;
            default:
                break;
        }
    }

    const createUser = () => {
        setLoading(true);
        users.create(payload)
        .then(res => {
            if (res.code === 201) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.add-successful'/>);

                reset();

                getUserList();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.add-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            setLoading(false);
        });
    }
    
    const updateUser = () => {
        setLoading(true);
        users.update(payload)
        .then(res => {
            if (res.status.code === 200) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.edit-successful'/>);

                reset();

                getUserList();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.edit-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            setLoading(false);
        });
    }
    
    const deleteUser = () => {
        setLoading(true);
        users.delete(payload)
        .then(res => {
            console.log(res);
            if (res.status.code === 200) {
                document.getElementById('deleteModal').click();
                toast.success(<FormattedMessage id='component.text.delete-successful'/>);

                reset();

                getUserList();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.delete-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            setLoading(false);
        });
    }

    const getUserInfo = (userInfo) => {
        payload.id = userInfo.id;
        payload.username = userInfo.username;
        payload.updated_by = userInfo.created_by;
        payload.status = userInfo.status;
        payload.level = userInfo.level;
        
        setPayload({ ...payload });
        setMode('Edit');
    }
    

    const reset = () => {
        // selectInputRef.current.select.clearValue();
        setPayload({
            id: '',
            username: '',
            password: '',
            status: true,
            level: '',
            created_by: 'ADMIN',
            updated_by: 'ADMIN'
        });
    }

    const data = {
        columns: [
          {
            label: <FormattedMessage id='form.number' defaultMessage='No'/>,
            field: 'no',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Username',
            field: 'username',
            sort: 'asc',
            width: 270
          },
          {
            label: <FormattedMessage id="form.user.role"/>,
            field: 'level_text',
            sort: 'asc',
            width: 200
          },
          {
            label: <FormattedMessage id="form.options"/>,
            field: 'option',
            sort: 'asc',
            width: 100
          },
        ],
        rows: userList
    };

    return (
            <div className="main">
                <ToastContainer
                    autoClose={5000}
                    hideProgressBar
                    pauseOnHover={false} />
                {/* MAIN CONTENT */}
                <div className="main-content">
                    <div className="container-fluid">
                        <h3 className="page-title">
                            <FormattedMessage id="menu.user"/>
                        </h3>
                        <div className="row">
                            <div className="col-md-12">
                                {/* BASIC TABLE */}
                                <div className="panel">
                                    <div className="panel-header mt-2 mr-2">
                                        <button 
                                            className="btn btn-primary" 
                                            data-toggle="modal" 
                                            data-target="#addModal" 
                                            style={{float : 'right'}} 
                                            onClick={() => setMode('Add')}> 
                                                <FormattedMessage id="component.add.button"/>
                                        </button>
                                    </div>
                                    <div className="panel-body">
                                        <Loading visible={loading}/>
                                        <MDBDataTable
                                            className="table mt-5"
                                            data={data}
                                            sortable
                                            noBottomColumns    
                                            entriesOptions={[5, 10, 20, 50]}
                                            displayEntries={true}
                                            paging={true}
                                            noRecordsFoundLabel={<FormattedMessage id="component.text.no-records"/>}                                                                                                                
                                        />                                    
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div> 
            
            <form onSubmit={e => {e.preventDefault(); _renderModalSubmit(mode)}}>  
                <Modal 
                title={mode === 'Add' ? 'Add User' : 'Edit User'}
                label={mode}
                form={
                        (
                            <>
                                <div className={mode === 'Add' ? 'col-md-6' : 'col-md-12'}>
                                    <div className="form-group">
                                        <label>Username</label>
                                        <input 
                                            type="text" 
                                            value={payload.username}
                                            className="form-control" 
                                            onChange={(e) => handlePayload(e, 'username')}
                                            required />
                                    </div>
                                </div>
                                {mode === 'Add' && (
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input 
                                                type="password" 
                                                value={payload.password}
                                                className="form-control" 
                                                onChange={(e) => handlePayload(e, 'password')}
                                                required />
                                        </div>                           
                                    </div>
                                )}                                                                
                            </>
                    )
                }
                onClose={() => reset()}
                onDelete={() => deleteUser()}
                disabled={loading}/>           
            </form>
        </div>        
    )
}
