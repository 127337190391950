export default {
    'component.login.button': 'Login',
    'component.add.button': 'Add',
    'component.edit.button': 'Edit',
    'component.delete.button': 'Delete',
    'component.close.button': 'Close',
    'component.list-subject.button': 'List Subject',
    'component.back.button': 'Back',
    'component.save.button': 'Save',

    'component.text.choose-file': 'Choose File',
    'component.text.confirm-delete-title': 'Attention',
    'component.text.confirm-delete': 'Are you sure want to delete this data?',
    'component.text.no-records': 'No records',
    'component.text.information': 'Information',

    'component.text.add-successful': 'Data added successfully',
    'component.text.edit-successful': 'Data edited successfully',
    'component.text.delete-successful': 'Data deleted successfully',
    'component.text.save-successful': 'Data saved successfully',
    'component.text.add-failed': 'Data failed to add',
    'component.text.edit-failed': 'Data failed to edit',
    'component.text.delete-failed': 'Data failed to delete',
    'component.text.save-failed': 'Data failed to save',

    'component.text.term': 'Term',
    'component.text.not-allowed-input-score': 'Not allowed to input this data yet. Please contact Administrator to grant permission',
    'component.text.assessment': 'Assessment',

    'component.text.waiting-data': 'Waiting for data...'
}