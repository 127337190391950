import React, {useState} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Route, Switch} from "react-router";
import Navbar from './pages/templates/Navbar';
import Sidebar from './pages/templates/Sidebar';
import Login from './pages/auth/Login';
import Dashboard from './pages/admin/Dashboard';
import About from './pages/admin/management/About';
import Event from './pages/admin/management/Event';
import Article from './pages/admin/management/Article';
import Academic from './pages/admin/management/Academic';
import SchoolManagement from './pages/admin/management/SchoolManagement';
import Gallery from './pages/admin/management/Gallery';
import Category from './pages/admin/management/Category';
import User from './pages/admin/management/User';
import { I18nPropvider, LOCALES } from './i18nProvider';
import Alumni from './pages/admin/management/Alumni';
import Admission from './pages/admin/management/Admission';
import Vacancies from './pages/admin/management/Vacancies';
import Testimonial from './pages/admin/management/Testimonial';
import Calendar from './pages/admin/management/Calendar';
import Achievment from './pages/admin/management/Achievment';
import Teacher from './pages/admin/management/Teacher';
import Vision from './pages/admin/management/Vision';
import Mission from './pages/admin/management/Mision';
import Principal from './pages/admin/management/Principal';
import Chairman from './pages/admin/management/Chairman';
import Activity from './pages/admin/management/Activity';
import Faq from './pages/admin/management/Faq';
import Fees from './pages/admin/management/Fees';
import Inquiry from './pages/admin/management/Inquiry';
import Apply from './pages/admin/management/Apply';
import Terms from './pages/admin/management/Terms';
import Guidelines from './pages/admin/management/Guidelines';
import Important from './pages/admin/management/Important';
import Child from './pages/admin/management/Child';
import Policy from './pages/admin/management/Policy';
import License from './pages/admin/management/License';
import Announcement from './pages/admin/management/Announcement';

function App() {
  const lsLocale = window.localStorage.getItem('locale');
  const [locale, setLocale] = useState(lsLocale || LOCALES.ENGLISH);
  return (
    <I18nPropvider locale={locale}>
      <div className="App">
              <BrowserRouter>
              <Switch>
                <Route exact path="/" component={Login} />
                <div>
                  <Navbar />
                  <Sidebar />
            
                  <Route exact path="/dashboard-admin" component={Dashboard}></Route>
                  <Route exact path="/about" component={About}></Route>
                  <Route exact path="/event" component={Event}></Route>
                  <Route exact path="/article" component={Article}></Route>
                  <Route exact path="/academic" component={Academic}></Route>
                  <Route exact path="/admission" component={Admission}></Route>
                  <Route exact path="/vacancies" component={Vacancies}></Route>
                  <Route exact path="/testimonial" component={Testimonial}></Route>
                  <Route exact path="/calendar" component={Calendar}></Route>
                  <Route exact path="/activity" component={Activity}></Route>
                  <Route exact path="/achievment" component={Achievment}></Route>
                  <Route exact path="/gallery" component={Gallery}></Route>
                  <Route exact path="/alumni" component={Alumni}></Route>
                  <Route exact path="/category" component={Category}></Route>
                  <Route exact path="/teacher" component={Teacher}></Route>
                  <Route exact path="/user" component={User}></Route>
                  <Route exact path="/vision" component={Vision}></Route>
                  <Route exact path="/mission" component={Mission}></Route>
                  <Route exact path="/principal" component={Principal}></Route>
                  <Route exact path="/chairman" component={Chairman}></Route>
                  <Route exact path="/faq" component={Faq}></Route>
                  <Route exact path="/fees" component={Fees}></Route>
                  <Route exact path="/inquiry" component={Inquiry}></Route>
                  <Route exact path="/apply" component={Apply}></Route>
                  <Route exact path="/terms" component={Terms}></Route>
                  <Route exact path="/guidelines" component={Guidelines}></Route>
                  <Route exact path="/important" component={Important}></Route>
                  <Route exact path="/child" component={Child}></Route>
                  <Route exact path="/policy" component={Policy}></Route>
                  <Route exact path="/license" component={License}></Route>
                  <Route exact path="/announcement" component={Announcement}></Route>
                  <Route exact path="/school-management" component={SchoolManagement}></Route>
            
                </div>

              </Switch>
          </BrowserRouter>
      </div>
    </I18nPropvider>
  );
}

export default App;
