import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Modal, Loading, TextError } from '../../../components';
import Constants from '../../../components/Constants';
import { SchoolManagement } from '../../../services';
import { MDBDataTable } from 'mdbreact';
import { FormattedMessage } from "react-intl";
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from "react-draft-wysiwyg";
import ImageUploader from 'react-images-upload';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-toastify/dist/ReactToastify.css';
require('dotenv').config();

const school_management = new SchoolManagement();

export default function SchoolManagements(props) {    
    const [mode, setMode] = useState('Add');
    const [loading, setLoading] = useState(false);
    const [editorState, setEditorState] = useState('');
    const [enEditorState, setEnEditorState] = useState('');
    const [schoolManagementList, setSchoolManagementList] = useState([]);
    const [payload, setPayload] = useState({
        id: '',
        name: '',
        content: '',
        created_by: 'ADMIN',
        updated_by: 'ADMIN',
        old_image: '',
        files: [],
        en_content: '',
    });

    useEffect(() => {
        (async () => getSchoolManagementList())();
    }, []);
    
    const getSchoolManagementList = () => {
        setLoading(true)

        school_management.list({ limit: 100 })
        .then(res => {
            console.log(res);    
            
            res.result.map((schoolManagement, i) => {
                res.result[i].no = i + 1; 
                res.result[i].display_image = 
                    <img 
                        src={getImage(schoolManagement.image)}
                        className="img-fluid" 
                        style={{width:'150px'}} 
                        title={schoolManagement.image} 
                        alt={schoolManagement.image}
                    />
                res.result[i].option = 
                    <>
                        <button 
                            className="btn btn-warning btn-sm mr-1" 
                            data-toggle="modal" 
                            data-target="#addModal" 
                            onClick={() => getSchoolManagementInfo(schoolManagement)}> 
                                <i className="lnr lnr-pencil"></i> 
                                <FormattedMessage id="component.edit.button"/>
                        </button>
                        <button 
                            className="btn btn-danger btn-sm" 
                            data-toggle="modal" 
                            data-target="#deleteModal"
                            onClick={() => getSchoolManagementInfo(schoolManagement)}> 
                                <i className="lnr lnr-trash"></i>
                                <FormattedMessage id="component.delete.button"/>
                        </button>                       
                    </>
            });
            
            setLoading(false)
            setSchoolManagementList(res.result);
        })
        .catch(err => {
            console.error(err);

            setLoading(false)        
            setSchoolManagementList([]);
        })
    }

    const getSchoolManagementInfo = (schoolManagement) => {      
        payload.id = schoolManagement.id;
        payload.name = schoolManagement.name;
        payload.content =  schoolManagement.content;
        payload.old_image = schoolManagement.image;    
        payload.en_content = schoolManagement.en_content;    
        
        changeEditorState(schoolManagement.content);
        changeEnEditorState(schoolManagement.en_content);
        setPayload({ ...payload });
        setMode('Edit');
    }

    const changeEditorState = (html) => {
        const es = EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(html)
            )
        );
        setEditorState(es);
    }
    const changeEnEditorState = (html) => {
        const es = EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(html)
            )
        );
        setEnEditorState(es);
    }
    const createSchoolManagement = () => {
        let fd = new FormData();
        for (let key in payload) {
            if (key === 'files') {
                fd.append(key, payload[key][0]);
            } else {
                fd.append(key, payload[key])
            }
        }
        setLoading(true)

        school_management.create(fd)
        .then(async res => {
            console.log(res);
            if (res.code === 201) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.add-successful'/>);

                await _handleResetPayload();
                await getSchoolManagementList();
            }
        })
        .catch(err => {
            console.error(err);
            toast.error(<div>{<FormattedMessage id='component.text.add-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)

            setLoading(false)
        })
    }

    const updateSchoolManagement = () => {
        let fd = new FormData();
        for (let key in payload) {
            if (key === 'files') {
                fd.append(key, payload[key][0]);
            } else {
                fd.append(key, payload[key])
            }
        }
        setLoading(true)

        school_management.update(payload.id, fd)
        .then(async res => {
            console.log(res);
            if (res.status.code === 200) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.edit-successful'/>);

                await _handleResetPayload();
                await getSchoolManagementList();
            }
        })
        .catch(err => {
            console.error(err);
            toast.error(<div>{<FormattedMessage id='component.text.edit-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)

            setLoading(false)
        })
    }

    const deleteSchoolManagement = () => {
        setLoading(true)

        school_management.delete(payload)
        .then(async res => {
            console.log(res);
            if (res.status.code === 200) {
                document.getElementById('deleteModal').click();
                toast.success(<FormattedMessage id='component.text.delete-successful'/>);

                await _handleResetPayload();
                await getSchoolManagementList();
            }
        })
        .catch(err => {
            console.error(err);
            toast.error(<div>{<FormattedMessage id='component.text.delete-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)

            setLoading(false)
        })
    }

    const getImage = (fileName) => {
        return `${process.env.REACT_APP_URL_SERVICE}/public/image/school_management/${fileName}`;
    }


    const onEditorStateChange = editorState => {
        setEditorState(editorState);
    }
    
    const onEnEditorStateChange = editorState => {
        setEnEditorState(editorState);
    }
    
    const _renderModalTitle = mode => {
        switch(mode) {
            case 'Add':
                return <FormattedMessage id="component.add.button"/>;
            case 'Edit':
                return <FormattedMessage id="component.edit.button"/>
            default:
                return 'Import'
        }
    }
    
    const _renderModalSubmit = mode => {
        switch(mode) {
            case 'Add':
                createSchoolManagement()
                break;
            case 'Edit':
                updateSchoolManagement()
                break;
            default:
                break;
        }
    }

    const _renderLevel = level => {
        for(let i in Constants.TYPE.LEVEL) {
            if (level === Constants.TYPE.LEVEL[i].value) {
                return Constants.TYPE.LEVEL[i].label;
            }
        }
    }
    const _handlePayload = (val, state) => {  
        if (state === 'content' || state === 'en_content') {
            val = draftToHtml(val);
        } 
        setPayload({
            ...payload,
            [state]: val
        });

    }

    const _handleResetPayload = () => {
        setPayload({
            ...payload,
            id: '',
            name: '',
            content: '',
            files: [],
            old_image: '',
            en_content: ''
        });

        changeEditorState('<p></p>');
        changeEnEditorState('<p></p>');
    }
    

    const data = {
        columns: [
          {
            label: <FormattedMessage id='form.number' defaultMessage='No'/>,
            field: 'no',
            sort: 'asc',
            width: 150
          },
          {
            label: <FormattedMessage id='form.category.name'/>,
            field: 'name',
            sort: 'asc',
            width: 270
          },
          {
            label: <FormattedMessage id='form.article.image'/>,
            field: 'display_image',
            sort: 'asc',
            width: 200
          },          
          {
            label: <FormattedMessage id='form.options'/>,
            field: 'option',
            sort: 'asc',
            width: 200
          },     
        ],
        rows: schoolManagementList || []
    };

    return (
        <div>
             <div className="main">

                <ToastContainer
                    autoClose={5000}
                    hideProgressBar
                    pauseOnHover={false} />
                <div className="main-content">
                    <div className="container-fluid">
                        <h3 className="page-title">
                            <FormattedMessage id="menu.school-management"/>
                        </h3>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-header mt-2 mr-2">                                    
                                        <div className="row">
                                            <div className="col-md-6"></div>
                                            <div className="col-md-6">  
                                                <button 
                                                    className="btn btn-primary" 
                                                    data-toggle="modal" 
                                                    data-target="#addModal" 
                                                    style={{float:'right'}}
                                                    onClick={() => setMode('Add')}
                                                ><FormattedMessage id="component.add.button"/></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel-body">    
                                        <Loading visible={loading}/>                                    
                                        <MDBDataTable
                                            className="table mt-5"
                                            data={data}
                                            sortable
                                            noBottomColumns    
                                            entriesOptions={[5, 10, 20, 50]}
                                            displayEntries={true}
                                            paging={true}
                                            noRecordsFoundLabel={<FormattedMessage id="component.text.no-records"/>}                                                                                                                
                                        />
                                    </div>
                                </div>
                                {/* Modal Add */}
                                <form onSubmit={e => {e.preventDefault(); _renderModalSubmit(mode)}}>  
                                    <Modal
                                        title={_renderModalTitle(mode)}
                                        label={mode}
                                        form={
                                            <>
                                                <div className="form-group">
                                                    <label><TextError/><FormattedMessage id="form.article.name"/></label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control"                                                    
                                                        onChange={e => _handlePayload(e.target.value, 'name')}
                                                        value={payload.name}
                                                        required/>
                                                </div>
                                                <div className="form-group" style={{border:'1px solid #eaeaea',padding:'10px',borderRadius:'5px'}}>
                                                    <label><TextError/><FormattedMessage id="form.vacancies.content"/></label>
                                                    <br/><br/>                                                
                                                    <Editor
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        toolbar={{
                                                            options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'remove', 'history'],
                                                            inline: { inDropdown: true },
                                                            list: { inDropdown: true },
                                                            link: { inDropdown: true },
                                                            history: { inDropdown: true },
                                                        }}
                                                        onContentStateChange={contentState => _handlePayload(contentState, 'content')}
                                                        onEditorStateChange={onEditorStateChange}
                                                        editorState={editorState}
                                                        required                                                    />
                                                </div>
                                                <div className="form-group" style={{border:'1px solid #eaeaea',padding:'10px',borderRadius:'5px'}}>
                                                    <label><TextError/><FormattedMessage id="form.vacancies.en_content"/></label>
                                                    <br/><br/>                                                
                                                    <Editor
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        toolbar={{
                                                            options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'remove', 'history'],
                                                            inline: { inDropdown: true },
                                                            list: { inDropdown: true },
                                                            link: { inDropdown: true },
                                                            history: { inDropdown: true },
                                                        }}
                                                        onContentStateChange={contentState => _handlePayload(contentState, 'en_content')}
                                                        onEditorStateChange={onEnEditorStateChange}
                                                        editorState={enEditorState}
                                                        required                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label><TextError/><FormattedMessage id="form.article.image"/></label>                    
                                                    <br/><br/>
                                                    {payload.old_image && (
                                                        <div style={{
                                                            textAlign: 'center'
                                                        }}>
                                                        
                                                            <img 
                                                                src={getImage(payload.old_image)} 
                                                                width="250"                                                            
                                                            />
                                                        </div>
                                                    )}
                                                    <ImageUploader
                                                        withIcon={true}
                                                        buttonText={
                                                            (payload.files && payload.files.length > 0) || payload.old_image
                                                            ? payload && payload.files.length > 0 && payload.files[0].name || payload.old_image
                                                            : 'Choose an image'
                                                        }
                                                        onChange={pic => _handlePayload([pic[pic.length - 1]], 'files')}
                                                        imgExtension={['.jpg', '.png']}
                                                        maxFileSize={5242880}
                                                        required
                                                    />
                                                </div>                                                                                                                                               
                                            </>
                                        }

                                        // onClick={(e) => {e.preventDefault; _renderModalSubmit(mode)}}
                                        onClose={() => _handleResetPayload()}
                                        onDelete={() => deleteSchoolManagement()}
                                        disabled={loading}
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
