export default {
    'menu.dashboard': 'Beranda',
    'menu.about': 'Tentang Kami',
    'menu.event': 'Acara',
    'menu.article': 'Berita Sekolah',
    'menu.academic': 'Akademik',
    'menu.admission': 'Pendaftaran',
    'menu.vacancies': 'Lowongan',
    'menu.academic-calendar': 'Kalender Akademik',
    'menu.calendar': 'Kalender',
    'menu.achievment': 'Prestasi',
    'menu.testimonial': 'Testimoni',
    'menu.testimonials': 'Testimoni',
    'menu.gallery': 'Galeri',
    'menu.category': 'Kategori',
    'menu.user': 'Pengguna',
    'menu.alumni': 'Alumni' ,
    'menu.language': 'Bahasa',          
    'menu.information': 'Informasi' ,     
    'menu.teacher': 'Profil Guru ',
    'menu.academic': 'Akademik',
    'menu.non-academic': 'Non Akademik',
    'menu.tour': 'Tur Sekolah Virtual',
    'menu.vision': 'Visi',
    'menu.mission': 'Misi',
    'menu.principal': 'Kepala Sekolah',
    'menu.chairman': 'Pimpinan',
    'menu.support': 'Support',
    'menu.activity-calendar': 'Kalender Kegiatan Sekolah',  
    'menu.faq': 'Faq',
    'menu.inquiry': 'Inquiry',
    'menu.fees': 'Fees',
    'menu.apply': 'Apply',
    'menu.terms': 'Terms and Conditional',
    'menu.guidelines': 'Admission Guidelines',
    'menu.important': 'Important Dates',
    'menu.child': 'Child Safeguarding',
    'menu.policy': 'Kebijakan Sekolah',
    'menu.license': 'Ijin Sekolah',
    'menu.announcement': 'Pengumuman',
    'menu.school-management': 'Management Sekolah',

}