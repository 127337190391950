import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import { NavLink } from 'react-router-dom';

export default class Sidebar extends Component {
    
    render() {
        return (
                <div id="sidebar-nav" className="sidebar">
                    <div className="menuu sidebar-scroll">
                        <nav>
                        <ul className="nav">     
                            <li><NavLink to="/dashboard-admin" activeClassName="active"><i className="lnr lnr-home" /> <span><FormattedMessage id="menu.dashboard"/></span></NavLink></li>
                            <li><NavLink to="/event" activeClassName="active"><i className="lnr lnr-calendar-full" /><FormattedMessage id="menu.event"/> <span></span></NavLink></li>
                            <li><NavLink to="/article" activeClassName="active"><i className="lnr lnr-book" /><FormattedMessage id="menu.article"/> <span></span></NavLink></li>
                            <li><NavLink to="/academic" activeClassName="active"><i className="lnr lnr-book" /><FormattedMessage id="menu.academic"/> <span></span></NavLink></li>
                            <li><NavLink to="/announcement" activeClassName="active"><i className="lnr lnr-bullhorn" /><FormattedMessage id="menu.announcement"/> <span></span></NavLink></li>
                            <li>
                                <a href="#subPages1" data-toggle="collapse" class="collapsed"><i class="lnr lnr-apartment"></i> <span><FormattedMessage id="menu.about"/></span> <i class="icon-submenu lnr lnr-chevron-left"></i></a>
                                    <div id="subPages1" class="collapse">
                                        <ul class="nav">
                                            <li><NavLink to="/about" activeClassName="active"><FormattedMessage id="menu.about"/><span></span></NavLink></li>
                                            <li><NavLink to="/principal" activeClassName="active"><FormattedMessage id="menu.principal"/> <span></span></NavLink></li>
                                            <li><NavLink to="/chairman" activeClassName="active"><FormattedMessage id="menu.chairman"/> <span></span></NavLink></li>
                                            <li><NavLink to="/vision" activeClassName="active"><FormattedMessage id="menu.vision"/> <span></span></NavLink></li>
                                            <li><NavLink to="/mission" activeClassName="active"><FormattedMessage id="menu.mission"/> <span></span></NavLink></li>
                                            <li><NavLink to="/policy" activeClassName="active"><FormattedMessage id="menu.policy"/> <span></span></NavLink></li>
                                            <li><NavLink to="/license" activeClassName="active"><FormattedMessage id="menu.license"/> <span></span></NavLink></li>
                                        </ul>
                                    </div>
						    </li>
                            <li>
                                <a href="#subPages2" data-toggle="collapse" class="collapsed"><i class="lnr lnr-bullhorn"></i> <span><FormattedMessage id="menu.vacancies"/></span> <i class="icon-submenu lnr lnr-chevron-left"></i></a>
                                    <div id="subPages2" class="collapse">
                                        <ul class="nav">
                                            <li><NavLink to="/admission" activeClassName="active"><FormattedMessage id="menu.academic"/> <span></span></NavLink></li>
                                            <li><NavLink to="/vacancies" activeClassName="active"><FormattedMessage id="menu.non-academic"/> <span></span></NavLink></li>
                                        </ul>
                                    </div>
						    </li>
                            <li>
                                <a href="#subPages3" data-toggle="collapse" class="collapsed"><i class="lnr lnr-star"></i> <span><FormattedMessage id="menu.support"/></span> <i class="icon-submenu lnr lnr-chevron-left"></i></a>
                                    <div id="subPages3" class="collapse">
                                        <ul class="nav">
                                            <li><NavLink to="/alumni" activeClassName="active"><FormattedMessage id="menu.alumni"/><span></span></NavLink></li>
                                            <li><NavLink to="/testimonial" activeClassName="active"><FormattedMessage id="menu.testimonial"/> <span></span></NavLink></li>
                                        </ul>
                                    </div>
						    </li>
                            <li>
                                <a href="#subPages4" data-toggle="collapse" class="collapsed"><i class="lnr lnr-picture"></i> <span><FormattedMessage id="menu.calendar"/></span> <i class="icon-submenu lnr lnr-chevron-left"></i></a>
                                    <div id="subPages4" class="collapse">
                                        <ul class="nav">
                                            <li><NavLink to="/calendar" activeClassName="active"><FormattedMessage id="menu.academic-calendar"/><span></span></NavLink></li>
                                            <li><NavLink to="/activity" activeClassName="active"><FormattedMessage id="menu.activity-calendar"/> <span></span></NavLink></li>
                                        </ul>
                                    </div>
						    </li>
                            <li>
                                <a href="#subPages5" data-toggle="collapse" class="collapsed"><i class="lnr lnr-cog"></i> <span><FormattedMessage id="menu.admission"/></span> <i class="icon-submenu lnr lnr-chevron-left"></i></a>
                                    <div id="subPages5" class="collapse">
                                        <ul class="nav">
                                            <li><NavLink to="/faq" activeClassName="active"><FormattedMessage id="menu.faq"/><span></span></NavLink></li>
                                            <li><NavLink to="/inquiry" activeClassName="active"><FormattedMessage id="menu.inquiry"/><span></span></NavLink></li>
                                            <li><NavLink to="/fees" activeClassName="active"><FormattedMessage id="menu.fees"/><span></span></NavLink></li>
                                            <li><NavLink to="/apply" activeClassName="active"><FormattedMessage id="menu.apply"/><span></span></NavLink></li>
                                            <li><NavLink to="/terms" activeClassName="active"><FormattedMessage id="menu.terms"/><span></span></NavLink></li>
                                            <li><NavLink to="/guidelines" activeClassName="active"><FormattedMessage id="menu.guidelines"/><span></span></NavLink></li>
                                            <li><NavLink to="/important" activeClassName="active"><FormattedMessage id="menu.important"/><span></span></NavLink></li>
                                            <li><NavLink to="/child" activeClassName="active"><FormattedMessage id="menu.child"/><span></span></NavLink></li>
                                          
                                        </ul>
                                    </div>
						    </li>
                            <li><NavLink to="/gallery" activeClassName="active"><i className="lnr lnr-picture" /><FormattedMessage id="menu.gallery"/><span></span></NavLink></li>
                            <li><NavLink to="/achievment" activeClassName="active"><i className="lnr lnr-graduation-hat" /><FormattedMessage id="menu.achievment"/><span></span></NavLink></li>
                            <li><NavLink to="/teacher" activeClassName="active"><i className="lnr lnr-users" /><FormattedMessage id="menu.teacher"/><span></span></NavLink></li>
                            <li><NavLink to="/category" activeClassName="active"><i className="lnr lnr-list" /><FormattedMessage id="menu.category"/><span></span></NavLink></li>
                            <li><NavLink to="/user" activeClassName="active"><i className="lnr lnr-user" /><FormattedMessage id="menu.user"/><span></span></NavLink></li>
                            <li><NavLink to="/school-management" activeClassName="active"><i className="lnr lnr-database" /><FormattedMessage id="menu.school-management"/><span></span></NavLink></li>
                        </ul>
                        </nav>
                    </div>
                </div>
        )
    }
}
