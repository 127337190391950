export default {
    // general
    'form.options': 'Opsi',
    'form.created_at': 'Tanggal dibuat',
    'form.number': 'No',
    'form.category': 'Kategori',
    'form.level': 'Kalangan',
    // article
    'form.article.title': 'Judul',
    'form.article.name': 'Nama',
    'form.article.content': 'Isi',
    'form.article.image': 'Gambar',  
    'form.article.videoURL': 'URL Video',  
    // event
    'form.event.start_at': 'Tanggal Mulai',
    'form.event.end_at': 'Tanggal Berakhir',
    'form.event.fee': 'Harga',  
    // category
    'form.category.name': 'Nama',
    'form.category.color': 'Warna',
    'form.category.color-picker': 'Pilih warna Label',
    // user
    'form.user.activate': 'Activate',
    'form.user.deactivate': 'Deavtivate',
    'form.user.active': 'Active',
    'form.user.deactive': 'Deactive',
    'form.user.role': 'Role',
    //alumni
    'form.alumni.name': 'Nama',
    'form.alumni.entry': 'Tahun Masuk',
    'form.alumni.grad': 'Tahun lulus',
    'form.alumni.department': 'Jurusan',
    'form.alumni.profession': 'Pekerjaan',
    'form.alumni.address': 'Alamat',
    'form.alumni.action': 'Aksi',
    // admission
    'form.admission.title': 'Judul (Bahasa)',
    'form.admission.en_title': 'Judul (English)',
    'form.admission.content': 'Isi (Bahasa)',
    'form.admission.en_content': 'Isi (English)',
    'form.admission.image': 'Gambar',  
    // vacancies
    'form.vacancies.title': 'Judul (Bahasa)',
    'form.vacancies.en_title': 'Judul (English)',
    'form.vacancies.content': 'Isi (Bahasa)',
    'form.vacancies.en_content': 'Isi (English)',
    'form.vacancies.image': 'Gambar',  
    // calendar
    'form.calendar.title': 'Judul',
    'form.calendar.content': 'Isi',
    'form.calendar.image': 'Gambar',  
    // achievment
    'form.achievment.title': 'Judul',
    'form.achievment.content': 'Isi',
    'form.achievment.image': 'Gambar',  
    // testimonial
    'form.testimonial.name': 'Nama Alumni',
    'form.testimonial.content': 'Isi (Bahasa)',
    'form.testimonial.en_content': 'Isi (English)',
    'form.testimonial.image': 'Gambar',  
    // teacher
    'form.teacher.name': 'Nama Guru',
    'form.teacher.position': 'Jabatan (Bahasa)',
    'form.teacher.en_position': 'Jabatan (English)'
}