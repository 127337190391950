import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Modal, Loading, TextError, RequiredSelect } from '../,,/../../../components';
import { Testimonial, Alumni } from '../,,/../../../services';
import { MDBDataTable } from 'mdbreact';
import { FormattedMessage } from "react-intl";
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import BaseSelect from 'react-select';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-toastify/dist/ReactToastify.css';
require('dotenv').config();

const testimonial = new Testimonial();
const alumni = new Alumni();

const Select = props => (
    <RequiredSelect
        {...props}
        SelectComponent={BaseSelect}
        options={props.options}
    />
);

export default function Testimonials(props) {    
    const [mode, setMode] = useState('Add');
    const [loading, setLoading] = useState(false);
    const [editorState, setEditorState] = useState('');
    const [enEditorState, setEnEditorState] = useState('');
    const [testimonialList, setTestimonialList] = useState([]);
    const [alumniList, setAlumniList] = useState([]);
    const [payload, setPayload] = useState({
        id: '',
        alumni_id: '',
        content: '',
        created_by: 'ADMIN',
        updated_by: 'ADMIN',
        en_content: ''
    });

    useEffect(() => {
        (async () => getTestimonialList())();
        (async () => getAlumniList())();
    }, []);

   
    const getTestimonialList = () => {
        setLoading(true)

        testimonial.list({ limit: 100 })
        .then(res => {
            console.log(res);    
            
            res.result.map((testimonial, i) => {
                res.result[i].no = i + 1; 
                res.result[i].alumni_name = testimonial.alumni.name;
                res.result[i].option = 
                    <>
                        <button 
                            className="btn btn-warning btn-sm mr-1" 
                            data-toggle="modal" 
                            data-target="#addModal" 
                            onClick={() => getTestimonialInfo(testimonial)}> 
                                <i className="lnr lnr-pencil"></i> 
                                <FormattedMessage id="component.edit.button"/>
                        </button>
                        <button 
                            className="btn btn-danger btn-sm" 
                            data-toggle="modal" 
                            data-target="#deleteModal"
                            onClick={() => getTestimonialInfo(testimonial)}> 
                                <i className="lnr lnr-trash"></i>
                                <FormattedMessage id="component.delete.button"/>
                        </button>                       
                    </>
            });
            
            setLoading(false)
            setTestimonialList(res.result);
        })
        .catch(err => {
            console.error(err);

            setLoading(false)        
            setTestimonialList([]);
        })
    }
    const getAlumniList = () => {
        alumni.list({ limit: 100 })
        .then(res => {
            console.log(res);         

            res.result.map((item, i) => {
                res.result[i].label = item.name;
                res.result[i].value = item.id;
            })
            setAlumniList(res.result);
        })
        .catch(err => {
            console.error(err);    
            setAlumniList([]);
        })
    }

    const getTestimonialInfo = (testimonial) => {      
        payload.id = testimonial.id;
        payload.alumni_id = testimonial.alumni_id;
        payload.content =  testimonial.content;
        payload.en_content = testimonial.en_content;
        
        changeEditorState(testimonial.content);
        changeEnEditorState(testimonial.en_content)
        setPayload({ ...payload });
        setMode('Edit');
    }

    const changeEditorState = (html) => {
        const es = EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(html)
            )
        );
        setEditorState(es);
    }

    const changeEnEditorState = (html) => {
        const es = EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(html)
            )
        );
        setEnEditorState(es);
    }
    
    const createTestimonial = () => {        
        setLoading(true)

        testimonial.create(payload)
        .then(res => {
            console.log(res);
            if (res.code === 201) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.add-successful'/>);

                _handleResetPayload();
                getTestimonialList();
            }
        })
        .catch(err => {
            console.error(err);
            toast.error(<div>{<FormattedMessage id='component.text.add-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)

            setLoading(false)
        })
    }

    const updateTestimonial = () => {        
        setLoading(true)

        testimonial.update(payload)
        .then(res => {
            console.log(res);
            if (res.status.code === 200) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.edit-successful'/>);

                _handleResetPayload();
                getTestimonialList();
            }
        })
        .catch(err => {
            console.error(err);
            toast.error(<div>{<FormattedMessage id='component.text.edit-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)

            setLoading(false)
        })
    }

    const deleteTestimonial = () => {
        setLoading(true)

        testimonial.delete(payload)
        .then(res => {
            console.log(res);
            if (res.status.code === 200) {
                document.getElementById('deleteModal').click();
                toast.success(<FormattedMessage id='component.text.delete-successful'/>);

                _handleResetPayload();
                getTestimonialList();
            }
        })
        .catch(err => {
            console.error(err);
            toast.error(<div>{<FormattedMessage id='component.text.delete-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)

            setLoading(false)
        })
    }


    const onEditorStateChange = editorState => {
        setEditorState(editorState);
    }
    const onEnEditorStateChange = editorState => {
        setEnEditorState(editorState);
    }
    
    const _renderModalTitle = mode => {
        switch(mode) {
            case 'Add':
                return <FormattedMessage id="component.add.button"/>;
            case 'Edit':
                return <FormattedMessage id="component.edit.button"/>
            default:
                return 'Import'
        }
    }
    
    const _renderModalSubmit = mode => {
        switch(mode) {
            case 'Add':
                createTestimonial()
                break;
            case 'Edit':
                updateTestimonial()
                break;
            default:
                break;
        }
    }
    const _handlePayload = (val, state) => {  
        if (state === 'content' || state === 'en_content') {
            val = draftToHtml(val);

        } 
        setPayload({
            ...payload,
            [state]: val
        });

    }

    const _handleResetPayload = () => {
        // selectInputRef.current.select.clearValue();
        setPayload({
            ...payload,
            id: '',
            name: '',
            content: '',
            en_content: '',
            status: false,
        });

        changeEditorState('<p></p>');
        changeEnEditorState('<p></p>');
    }
    

    const data = {
        columns: [
          {
            label: <FormattedMessage id='form.number' defaultMessage='No'/>,
            field: 'no',
            sort: 'asc',
            width: 150
          },
          {
            label: <FormattedMessage id='form.testimonial.name'/>,
            field: 'alumni_name',
            sort: 'asc',
            width: 270
          },
          {
            label: <FormattedMessage id='form.options'/>,
            field: 'option',
            sort: 'asc',
            width: 200
          },     
        ],
        rows: testimonialList || []
    };

    return (
        <div>
             <div className="main">

                <ToastContainer
                    autoClose={5000}
                    hideProgressBar
                    pauseOnHover={false} />
                <div className="main-content">
                    <div className="container-fluid">
                        <h3 className="page-title">
                            <FormattedMessage id="menu.testimonial"/>
                        </h3>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-header mt-2 mr-2">                                    
                                        <div className="row">
                                            <div className="col-md-6"></div>
                                            <div className="col-md-6">  
                                                <button 
                                                    className="btn btn-primary" 
                                                    data-toggle="modal" 
                                                    data-target="#addModal" 
                                                    style={{float:'right'}}
                                                    onClick={() => setMode('Add')}
                                                ><FormattedMessage id="component.add.button"/></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel-body">    
                                        <Loading visible={loading}/>                                    
                                        <MDBDataTable
                                            className="table mt-5"
                                            data={data}
                                            sortable
                                            noBottomColumns    
                                            entriesOptions={[5, 10, 20, 50]}
                                            displayEntries={true}
                                            paging={true}
                                            noRecordsFoundLabel={<FormattedMessage id="component.text.no-records"/>}                                                                                                                
                                        />
                                    </div>
                                </div>
                                {/* Modal Add */}
                                <form onSubmit={e => {e.preventDefault(); _renderModalSubmit(mode)}}>  
                                    <Modal
                                        title={_renderModalTitle(mode)}
                                        label={mode}
                                        form={
                                            <>
                                                <div className="form-group">
                                                    <label><TextError/><FormattedMessage id="form.testimonial.name"/></label><br></br>                                                    
                                                    <Select
                                                        className="mt-1"
                                                        value={
                                                            alumniList?.length > 0 
                                                            ? alumniList.find(item => item.id === payload.category_id)
                                                            : null
                                                        }                        
                                                        placeholder={"Select a alumni"}           
                                                        options={alumniList}                                    
                                                        onChange={(e) => _handlePayload(e.id, 'alumni_id')}
                                                        // ref={selectInputRef}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group" style={{border:'1px solid #eaeaea',padding:'10px',borderRadius:'5px'}}> 
                                                    <label><TextError/><FormattedMessage id="form.testimonial.content"/></label>
                                                    <br/><br/>                                                
                                                    <Editor
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        toolbar={{
                                                            options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'remove', 'history'],
                                                            inline: { inDropdown: true },
                                                            list: { inDropdown: true },
                                                            link: { inDropdown: true },
                                                            history: { inDropdown: true },
                                                        }}
                                                        onContentStateChange={contentState => _handlePayload(contentState, 'content')}
                                                        onEditorStateChange={onEditorStateChange}
                                                        editorState={editorState}
                                                        required                                                    />
                                                </div>
                                               
                                                <div className="form-group" style={{border:'1px solid #eaeaea',padding:'10px',borderRadius:'5px'}}> 
                                                    <label><TextError/><FormattedMessage id="form.testimonial.en_content"/></label>
                                                    <br/><br/>                                                
                                                    <Editor
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        toolbar={{
                                                            options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'remove', 'history'],
                                                            inline: { inDropdown: true },
                                                            list: { inDropdown: true },
                                                            link: { inDropdown: true },
                                                            history: { inDropdown: true },
                                                        }}
                                                        onContentStateChange={contentState => _handlePayload(contentState, 'en_content')}
                                                        onEditorStateChange={onEnEditorStateChange}
                                                        editorState={enEditorState}
                                                        required                                                    />
                                                </div>
                                            </>
                                        }
                                        onClose={() => _handleResetPayload()}
                                        onDelete={() => deleteTestimonial()}
                                        disabled={loading}
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
