import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from "react-intl";
import { Leadership } from '../,,/../../../services';
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from "react-draft-wysiwyg";
import { ToastContainer, toast } from 'react-toastify';
import { Constants, Loading, TextError } from '../../../components';
import ImageUploader from 'react-images-upload';
import draftToHtml from 'draftjs-to-html';
import Select from 'react-select';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-toastify/dist/ReactToastify.css';
require('dotenv').config();

const principal = new Leadership();
export default function Principal(props) {    
    useEffect(() => {
        (async () => getPrincipalInfo())();
    }, []);

    const [loading, setLoading] = useState(false);
    const [editorState, setEditorState] = useState('');
    const [payload, setPayload] = useState({
        id: '',
        name: '',
        content: '',
        level: 'PRINCIPAL',
        created_by: 'ADMIN',
        updated_by: 'ADMIN',
        old_image: '',
        files: [],
        lang: null
    });

    const getPrincipalInfo = (lang = 'id-ID') => {
        setLoading(true);

        principal.getLeadership(payload.level, lang)
        .then(res => {
            console.log(res);
            const {id, content, image, name, language } = res.result;
            payload.id = id;
            payload.name = name;
            payload.content = content;
            payload.old_image = image;
            
            const lang = Constants.LANGUAGES.find(item => item.value === language)
            payload.lang = lang;

            changeEditorState(content);
            setPayload({ ...payload });
            setLoading(false);
        })
        .catch(err => {
            console.error(err);
            payload.id = '';        
            payload.content = '';
            payload.lang = Constants.LANGUAGES.find(item => item.value === lang);

            setPayload({
                ...payload,
            })

            
            changeEditorState('<p></p>');
            
            setLoading(false);
        })
    }

    const createPrincipal = () => {
        setLoading(true);
        const fd = new FormData();
        for (let key in payload) {
            if (key === 'files') {
                fd.append(key, payload[key][0]);
            } else if (key === 'lang') {
                fd.append(key, payload[key]['value'])
            } else {
                fd.append(key, payload[key])
            }
        }
        principal.create(fd)
        .then(res => {
            console.log(res);
            toast.success(<FormattedMessage id='component.text.edit-successful'/>);

            getPrincipalInfo(payload.lang.value);

        })
        .catch(err => {
            console.error(err);
            toast.error(<div>{<FormattedMessage id='component.text.edit-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)

            setLoading(false);
        })
    }
    const onEditorStateChange = editorState => {
        setEditorState(editorState);
    }

    const changeEditorState = (html) => {
        const es = EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(html)
            )
        );
        setEditorState(es);
    }
    const getImage = (fileName) => {
        return `${process.env.REACT_APP_URL_SERVICE}/public/image/leadership/${fileName}`;
    }

    const _handlePayload = (val, state) => {  
        console.log({ val })
        if (state === 'content') {
            val = draftToHtml(val);

        } 
        setPayload({
            ...payload,
            [state]: val
        });

    }
    
    const selectInputRef = useRef();

    return (
        <div>
        <div className="main">
            <ToastContainer
                autoClose={5000}
                hideProgressBar
                pauseOnHover={false} />
            <div className="main-content">
                <div className="container-fluid">
                    <h3 className="page-title">
                        <FormattedMessage id="menu.principal"/>
                    </h3>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel">
                                <div className="panel-header mt-2 mr-2">                                    
                                    <div className="row">

                                    </div>
                                </div>
                                <Loading visible={loading}/>
                                {!loading && (
                                    <>
                                        <div className="panel-body">
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                                                <div className='pr-2'>
                                                    Bahasa: 
                                                </div>
                                                <div className="form-group">
                                                    <Select
                                                        value={payload.lang}                        
                                                        placeholder={"Select a language"}           
                                                        options={Constants.LANGUAGES}                                    
                                                        onChange={(e) => {
                                                            // _handlePayload(e, 'lang');
                                                            getPrincipalInfo(e.value)
                                                        }}
                                                        ref={selectInputRef}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                <label><TextError/> <FormattedMessage id="form.article.name"/></label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        value={payload.name} 
                                                        onChange={e => _handlePayload(e.target.value, 'name')}/>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="form-group">
                                                <label><TextError/> <FormattedMessage id="form.article.image"/></label>                                                                                                                                               
                                                <br/><br/>
                                                {payload.old_image && (
                                                    <div style={{
                                                        textAlign: 'center'
                                                    }}>
                                                    
                                                        <img 
                                                            src={getImage(payload.old_image)} 
                                                            width="250"                                                            
                                                        />
                                                    </div>
                                                )}
                                                <ImageUploader
                                                    withIcon={true}
                                                    buttonText={payload.files.length > 0 && payload.files[0]?.name || payload.old_image || 'Choose an image'}
                                                    imgExtension={['.jpg', '.png']}
                                                    maxFileSize={5242880}
                                                    onChange={pic => _handlePayload([pic[pic.length - 1]], 'files')}
                                                />
                                            </div>
                                            <div className="form-group" style={{border:'1px solid #eaeaea',padding:'10px',borderRadius:'5px'}}>
                                                <label><TextError/><FormattedMessage id="form.article.content"/></label> 
                                                <br/><br/>
                                                <Editor 
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    toolbar={{
                                                        options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'remove', 'history'],
                                                        inline: { inDropdown: true },
                                                        list: { inDropdown: true },
                                                        link: { inDropdown: true },
                                                        history: { inDropdown: true },
                                                    }}
                                                    onContentStateChange={contentState => _handlePayload(contentState, 'content')}
                                                    onEditorStateChange={onEditorStateChange}
                                                    editorState={editorState}
                                                />
                                            </div>
                                        
                                        </div>
                                        <div className="panel-footer">
                                            <button type="button" className="btn btn-primary" onClick={createPrincipal} disabled={loading}>
                                                <FormattedMessage id="component.save.button"/>
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}