require('dotenv').config();
export default class VisionMission {
    
    constructor() {}

    getVisionOrMission = async (type, lang) => {        
        const token = window.localStorage.getItem('token');        

        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/vision-mission/detail/${type}?lang=${lang}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(res => {
                if (res.status === 401) {
                    window.location.href = '/';
                }
                res.json()
                .then(data => {
                    if(data.status && data.status.code === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        });
    }
    create = async (payload) => {
        const token = window.localStorage.getItem('token');

        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/vision-mission/upsert`, {
                method: 'POST', 
                headers: {                    
                    'Authorization': `Bearer ${token}`
                },               
                body: payload
            })
            .then(res => {
                if (res.status === 401) {
                    window.location.href = '/';
                }
                res.json()
                .then(data => {
                    if(data.code === 201) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        });
    }    
}