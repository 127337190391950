import React from 'react';


export default function Dashboard(props) {
return (
    <div>
    <div className="main">
        <div className="main-content">
            <div className="container-fluid">
                <div className="panel panel-profile">
                    <div className="clearfix">
                        <div className="profile-left">
                            <div className="profile-header">
                                <div className="overlay" />
                                    <div className="profile-main">
                                    <img src="assets/img/user.png" style={{width:'80px'}} className="img-circle" alt="Avatar" />
                                        <h3 className="name">Admin</h3>
                                        <span className="online-status status-available">Admin - Admin</span>
                                    </div>
                                    <div className="profile-detail">
                                        <div className="profile-info">
                                          
                                        </div>
                                    </div>
              
                                 </div>
                            </div>
                        <div className="profile-right">
                            <h4 className="heading text-center">Information</h4>
                            {/* AWARDS */}
                            <div className="awards">
                                <div className="row">
                                    <div className="col-md-3 col-sm-6">
                                        <div className="award-item">
                                            <div className="hexagon">
                                            <span className="lnr lnr-user award-icon" />
                                            </div>
                                            <span>Management About</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="award-item">
                                            <div className="hexagon">
                                            <span className="lnr lnr-users award-icon" />
                                            </div>
                                            <span>Management Gallery</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="award-item">
                                            <div className="hexagon">
                                            <span className="lnr lnr-apartment award-icon" />
                                            </div>
                                            <span>Management Article</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="award-item">
                                            <div className="hexagon">
                                            <span className="lnr lnr-list award-icon" />
                                            </div>
                                            <span>Management About</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* END AWARDS */}
                        </div>
                            {/* END RIGHT COLUMN */}
                    </div>
                </div>
            </div>
        </div>
    {/* END MAIN CONTENT */}
    </div>
</div>
)
}