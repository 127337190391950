import React, { Component } from 'react'
import { FormattedMessage } from "react-intl";

export default class Modal extends Component {
    render() {
        const { form, onClose, onDelete, title, label, onSubmit, disabled } = this.props;
        return (
            <div>
                <div className="modal fade" id="addModal" tabIndex={-1} aria-labelledby="addModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
                                    <span aria-hidden="true">×</span>
                                </button>
                                <h5 className="modal-title" id="addModalLabel">{title}</h5>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        {form}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={onClose}>
                                    <FormattedMessage id="component.close.button"/>
                                </button>
                                <button type="submit" className="btn btn-primary" disabled={disabled || false}>{label}</button>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div>
                    <div className="modal fade" id="deleteModal" tabIndex={-1} aria-labelledby="deleteModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <h5 className="modal-title" id="deleteModalLabel" style={{textAlign:'left'}}>
                                        <FormattedMessage id="component.text.confirm-delete-title"/>
                                    </h5>
                                </div>
                                <div className="modal-body" style={{textAlign:'left'}}>
                                    <FormattedMessage id="component.text.confirm-delete"/>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-secondary" data-dismiss="modal" onClick={onClose}>
                                        <FormattedMessage id="component.close.button"/>
                                    </button>
                                    <button type="submit" className="btn btn-danger" onClick={onDelete} disabled={disabled || false}>
                                        <FormattedMessage id="component.delete.button"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
