import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Modal, Loading, TextError } from '../../../components';
import { Teachers } from '../../../services';
import { MDBDataTable } from 'mdbreact';
import { FormattedMessage } from "react-intl";
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from "react-draft-wysiwyg";
import ImageUploader from 'react-images-upload';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-toastify/dist/ReactToastify.css';
require('dotenv').config();


const teacher = new Teachers();

export default function Teacher(props) {   
    const [mode, setMode] = useState('Add');
    const [loading, setLoading] = useState(false);
    const [TeacherList, setTeacherList] = useState([]);
    const [payload, setPayload] = useState({
        id: '',
        name: '',
        position: '',
        status: false,
        created_by: 'ADMIN',
        updated_by: 'ADMIN',
        old_image: '',
        files: [],
        en_position: ''
    });


    useEffect(() => {
        (async () => getTeacherList())();
    }, []);

    const getTeacherList = () => {
        setLoading(true);

        teacher.list({ limit: 100 })
        .then(res => {
            console.log(res);    
            
            res.result.map((teacher, i) => {
                res.result[i].no = i + 1; 
                res.result[i].display_image = 
                    <img 
                        src={getImage(teacher.image)}
                        className="img-fluid" 
                        style={{width:'150px'}} 
                        title={teacher.image} 
                        alt={teacher.image}
                    />
                res.result[i].option = 
                    <>
                        <button 
                            className="btn btn-warning btn-sm mr-1" 
                            data-toggle="modal" 
                            data-target="#addModal" 
                            onClick={() => getTeacherInfo(teacher)}> 
                                <i className="lnr lnr-pencil"></i> 
                                <FormattedMessage id="component.edit.button"/>
                        </button>
                        <button 
                            className="btn btn-danger btn-sm" 
                            data-toggle="modal" 
                            data-target="#deleteModal"
                            onClick={() => getTeacherInfo(teacher)}> 
                                <i className="lnr lnr-trash"></i>
                                <FormattedMessage id="component.delete.button"/>
                        </button>                       
                    </>
            });
            
            setLoading(false);
            setTeacherList(res.result);
        })
        .catch(err => {
            console.error(err);

            setLoading(false);        
            setTeacherList([]);
        })
    }

    const getTeacherInfo = (teacher) => {      
        payload.id = teacher.id;
        payload.name = teacher.name;
        payload.position =  teacher.position;
        payload.status = teacher.status;
        payload.old_image = teacher.image;    
        payload.en_position = teacher.en_position;    
        
        setPayload({ ...payload });
        setMode('Edit');
    }
    
    const createTeacher= () => {
        let fd = new FormData();
        for (let key in payload) {
            if (key === 'files') {
                fd.append(key, payload[key][0]);
            } else {
                fd.append(key, payload[key])
            }
        }


        setLoading(true);

        teacher.create(fd)
        .then(async res => {
            console.log(res);
            if (res.code === 201) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.add-successful'/>);

                await _handleResetPayload();
                await getTeacherList();
            }
        })
        .catch(err => {
            console.error(err);
            toast.error(<div>{<FormattedMessage id='component.text.add-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)

            setLoading(false);
        })
    }

    const updateTeacher = () => {
        let fd = new FormData();
        for (let key in payload) {
            if (key === 'files') {
                fd.append(key, payload[key][0]);
            } else {
                fd.append(key, payload[key])
            }
        }
        setLoading(true);

        teacher.update(payload.id, fd)
        .then(res => {
            console.log(res);
            if (res.status.code === 200) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.edit-successful'/>);

                _handleResetPayload();
                getTeacherList();
            }
        })
        .catch(err => {
            console.error(err);
            toast.error(<div>{<FormattedMessage id='component.text.edit-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)

            setLoading(false);
        })
    }

    const deleteTeacher = () => {
        setLoading(true);

        teacher.delete(payload)
        .then(res => {
            console.log(res);
            if (res.status.code === 200) {
                document.getElementById('deleteModal').click();
                toast.success(<FormattedMessage id='component.text.delete-successful'/>);

                _handleResetPayload();
                getTeacherList();
            }
        })
        .catch(err => {
            console.error(err);
            toast.error(<div>{<FormattedMessage id='component.text.delete-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)

            setLoading(false);
        })
    }

    const getImage = (fileName) => {
        return `${process.env.REACT_APP_URL_SERVICE}/public/image/teachers/${fileName}`;
    }
    
    
    const _renderModalTitle = mode => {
        switch(mode) {
            case 'Add':
                return <FormattedMessage id="component.add.button"/>;
            case 'Edit':
                return <FormattedMessage id="component.edit.button"/>
            default:
                return 'Import'
        }
    }
    
    const _renderModalSubmit = mode => {
        switch(mode) {
            case 'Add':
                createTeacher()
                break;
            case 'Edit':
                updateTeacher()
                break;
            default:
                break;
        }
    }
    const _handlePayload = (val, state) => {           
        setPayload({
            ...payload,
            [state]: val
        });

    }

    const _handleResetPayload = () => {
        setPayload({
            ...payload,
            id: '',
            category_id: '',
            name: '',
            position: '',
            status: false,
            files: [],
            old_image: '',
            en_position: ''
        });

    }
    

    const data = {
        columns: [
          {
            label: <FormattedMessage id='form.number' defaultMessage='No'/>,
            field: 'no',
            sort: 'asc',
            width: 150
          },
          {
            label: <FormattedMessage id='form.teacher.name'/>,
            field: 'name',
            sort: 'asc',
            width: 270
          },
          {
            label: <FormattedMessage id='form.teacher.position'/>,
            field: 'position',
            sort: 'asc',
            width: 270
          },
          {
            label: <FormattedMessage id='form.article.image'/>,
            field: 'display_image',
            sort: 'asc',
            width: 200
          },    
          {
            label: <FormattedMessage id='form.options'/>,
            field: 'option',
            sort: 'asc',
            width: 200
          },     
        ],
        rows: TeacherList || []
    };

    return (
        <div>
             <div className="main">

                <ToastContainer
                    autoClose={5000}
                    hideProgressBar
                    pauseOnHover={false} />
                <div className="main-content">
                    <div className="container-fluid">
                        <h3 className="page-title">
                            <FormattedMessage id="menu.teacher"/>
                        </h3>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-header mt-2 mr-2">                                    
                                        <div className="row">
                                            <div className="col-md-6"></div>
                                            <div className="col-md-6">  
                                                <button 
                                                    className="btn btn-primary" 
                                                    data-toggle="modal" 
                                                    data-target="#addModal" 
                                                    style={{float:'right'}}
                                                    onClick={() => setMode('Add')}
                                                ><FormattedMessage id="component.add.button"/></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel-body"> 
                                        <Loading visible={loading}/>
                                        <MDBDataTable
                                            className="table mt-5"
                                            data={data}
                                            sortable
                                            noBottomColumns    
                                            entriesOptions={[5, 10, 20, 50]}
                                            displayEntries={true}
                                            paging={true}
                                            noRecordsFoundLabel={<FormattedMessage id="component.text.no-records"/>}                                                                                                                
                                        />
                                    </div>
                                </div>
                                {/* Modal Add */}                                
                                <form onSubmit={e => {e.preventDefault(); _renderModalSubmit(mode)}}>  
                                    <Modal
                                        title={_renderModalTitle(mode)}
                                        label={mode}
                                        form={
                                            <>
                                                <div className="form-group">
                                                    <label><TextError/><FormattedMessage id="form.teacher.name"/></label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control"                                                    
                                                        onChange={e => _handlePayload(e.target.value, 'name')}
                                                        value={payload.name}
                                                        required/>
                                                </div>
                                                <div className="form-group">
                                                    <label><TextError/><FormattedMessage id="form.teacher.position"/></label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control"                                                    
                                                        onChange={e => _handlePayload(e.target.value, 'position')}
                                                        value={payload.position}
                                                        required/>
                                                </div>   
                                                <div className="form-group">
                                                    <label><TextError/><FormattedMessage id="form.teacher.en_position"/></label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control"                                                    
                                                        onChange={e => _handlePayload(e.target.value, 'en_position')}
                                                        value={payload.en_position}
                                                        required/>
                                                </div>                                                  
                                                <div className="form-group">
                                                    <label><TextError/><FormattedMessage id="form.article.image"/></label>                  
                                                    <br/><br/>
                                                    {payload.old_image && (
                                                        <div style={{
                                                            textAlign: 'center'
                                                        }}>
                                                        
                                                            <img 
                                                                src={getImage(payload.old_image)} 
                                                                width="250"                                                            
                                                            />
                                                        </div>
                                                    )}
                                                    <ImageUploader
                                                        withIcon={true}
                                                        buttonText={
                                                            (payload.files && payload.files.length > 0) || payload.old_image
                                                            ? payload && payload.files.length > 0 && payload.files[0].name || payload.old_image
                                                            : 'Choose an image'
                                                        }
                                                        onChange={pic => _handlePayload([pic[pic.length - 1]], 'files')}
                                                        imgExtension={['.jpg', '.png']}
                                                        maxFileSize={5242880}
                                                    />
                                                </div>                                           
                                            </>
                                        }

                                        onClose={() => _handleResetPayload()}
                                        onDelete={() => deleteTeacher()}
                                        disabled={loading}
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
