import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Modal, Loading, TextError } from '../../../components';
import { Alumni } from '../../../services';
import { MDBDataTable } from 'mdbreact';
import { FormattedMessage } from "react-intl";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-toastify/dist/ReactToastify.css';
require('dotenv').config();

const alumni = new Alumni();

export default function Alumnies(props) {   
    const [mode, setMode] = useState('Add');
    const [loading, setLoading] = useState(false);
    const [alumniList, setAlumniList] = useState([]);
    const [payload, setPayload] = useState({
        id: '',
        name: '',
        year_in: '',
        year_out: '',
        major: '',
        profession: '',
        address: '',
        created_by: 'ADMIN',
        updated_by: 'ADMIN',
        old_image: '',
        files: []
    });

    useEffect(() => {
        (async () => getAlumniList())();
    }, []);
    
    const getAlumniList = () => {
        setLoading(true);

        alumni.list({ limit: 100 })
        .then(res => {
            console.log(res);

            res.result.map((cat, i) => {
                res.result[i].no = i + 1;
                res.result[i].option = 
                    <>
                        <button 
                            className="btn btn-warning btn-sm mr-1" 
                            data-toggle="modal" 
                            data-target="#addModal" 
                            onClick={() => getAlumniInfo(cat)}> 
                                <i className="lnr lnr-pencil"></i> 
                                <FormattedMessage id="component.edit.button"/>
                        </button>
                        <button 
                            className="btn btn-danger btn-sm" 
                            data-toggle="modal" 
                            data-target="#deleteModal"
                            onClick={() => getAlumniInfo(cat)}> 
                                <i className="lnr lnr-trash"></i>
                                <FormattedMessage id="component.delete.button"/>
                        </button>                       
                    </>
            });
        
            setLoading(false);
            setAlumniList(res.result);
        })
        .catch(err => {
            console.error(err);
            
            setLoading(false);
            setAlumniList([]);
        })
    }

    const getAlumniInfo = (alumni) => {      
        payload.id = alumni.id;
        payload.name = alumni.name; 
        payload.year_in = alumni.year_in;      
        payload.year_out = alumni.year_out;      
        payload.major = alumni.major;
        payload.profession = alumni.profession;
        payload.address = alumni.address;
        
        setPayload({ ...payload });
        setMode('Edit');
    }

    const createAlumni = () => {        
        setLoading(true);

        alumni.create(payload)
        .then(res => {
            console.log(res);
            if (res.code === 201) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.add-successful'/>);

                _handleResetPayload();
                getAlumniList();
            }
        })
        .catch(err => {
            console.error(err);
            toast.error(<div>{<FormattedMessage id='component.text.add-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)

            setLoading(false);
        })
    }

    const updateAlumni = () => {        
        setLoading(true);

        alumni.update(payload)
        .then(res => {
            console.log(res);
            if (res.status.code === 200) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.edit-successful'/>);

                _handleResetPayload();
                getAlumniList();
            }
        })
        .catch(err => {
            console.error(err);
            toast.error(<div>{<FormattedMessage id='component.text.edit-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)

            setLoading(false);
        })
    }

    const deleteAlumni = () => {
        setLoading(true);

        alumni.delete(payload)
        .then(res => {
            console.log(res);
            if (res.status.code === 200) {
                document.getElementById('deleteModal').click();
                toast.success(<FormattedMessage id='component.text.delete-successful'/>);

                _handleResetPayload();
                getAlumniList();
            }
        })
        .catch(err => {
            console.error(err);
            toast.error(<div>{<FormattedMessage id='component.text.delete-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)

            setLoading(false);
        })
    }
    
    const _renderModalTitle = mode => {
        switch(mode) {
            case 'Add':
                return <FormattedMessage id="component.add.button"/>;
            case 'Edit':
                return <FormattedMessage id="component.edit.button"/>
            default:
                return 'Import'
        }
    }
    
    const _renderModalSubmit = mode => {
        switch(mode) {
            case 'Add':
                createAlumni()
                break;
            case 'Edit':
                updateAlumni()
                break;
            default:
                break;
        }
    }
    const _handlePayload = (val, state) => {         
        setPayload({
            ...payload,
            [state]: val
        });

    }

    const _handleResetPayload = () => {
        setPayload({
            ...payload,
            id: '',
            name: '', 
            year_in: '',
            year_out: '',
            major: '',
            profession: '',
            address: ''          
        });
    }
    

    const data = {
        columns: [
          {
            label: <FormattedMessage id='form.number' defaultMessage='No'/>,
            field: 'no',
            sort: 'asc',
            width: 150
          },
          {
            label: <FormattedMessage id='form.alumni.name'/>,
            field: 'name',
            sort: 'asc',
            width: 270
          },
          {
            label: <FormattedMessage id='form.alumni.entry'/>,
            field: 'year_in',
            sort: 'asc',
            width: 200
          },
          {
            label: <FormattedMessage id='form.alumni.grad'/>,
            field: 'year_out',
            sort: 'asc',
            width: 200
          },
          {
            label: <FormattedMessage id='form.alumni.department'/>,
            field: 'major',
            sort: 'asc',
            width: 200
          },
          {
            label: <FormattedMessage id='form.alumni.profession'/>,
            field: 'profession',
            sort: 'asc',
            width: 200
          },
          {
            label: <FormattedMessage id='form.alumni.address'/>,
            field: 'address',
            sort: 'asc',
            width: 200
          },
          {
            label: <FormattedMessage id='form.options'/>,
            field: 'option',
            sort: 'asc',
            width: 200
          },     
        ],
        rows: alumniList || []
    };
    return (
        <div>
            <div className="main">
                <ToastContainer
                    autoClose={5000}
                    hideProgressBar
                    pauseOnHover={false} />
                <div className="main-content">
                    <div className="container-fluid">
                        <h3 className="page-title">
                            <FormattedMessage id="menu.alumni"/>
                        </h3>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-header mt-2 mr-2">                                    
                                        <div className="row">
                                            <div className="col-md-6"></div>
                                            <div className="col-md-6"> 
                                                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#addModal" style={{float:'right'}} onClick={() => setMode('Add')}>
                                                    <FormattedMessage id="component.add.button"/>
                                                </button>
                                            </div>
                                        </div> 
                                    </div>
                                    <div className="panel-body">
                                        <Loading visible={loading}/>                                    
                                        <MDBDataTable
                                            className="table mt-5"
                                            data={data}
                                            sortable
                                            noBottomColumns    
                                            entriesOptions={[5, 10, 20, 50]}
                                            displayEntries={true}
                                            paging={true}
                                            noRecordsFoundLabel={<FormattedMessage id="component.text.no-records"/>}                                                                                                                
                                        />
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal */}
                <form onSubmit={e => {e.preventDefault(); _renderModalSubmit(mode)}}> 
                    <Modal
                        title={_renderModalTitle(mode)}
                        label={mode}
                        form={
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label><TextError/><FormattedMessage id="form.alumni.name"/></label>
                                    <input type="text" name="name" className="form-control" id="name" required onChange={e => _handlePayload(e.target.value, 'name')} value={payload.name}/>
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label><TextError/><FormattedMessage id="form.alumni.entry"/></label>
                                    <input type="number" className="form-control" name="entry" id="entry" required onChange={e => _handlePayload(e.target.value, 'year_in')} value={payload.year_in}/>
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label><TextError/><FormattedMessage id="form.alumni.grad"/></label>
                                    <input type="number" className="form-control" name="grad" id="grad" required onChange={e => _handlePayload(e.target.value, 'year_out')} value={payload.year_out}/>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label><TextError/><FormattedMessage id="form.alumni.department"/></label>
                                    <input type="text" className="form-control" name="department" id="department" required onChange={e => _handlePayload(e.target.value, 'major')} value={payload.major}/>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label><TextError/><FormattedMessage id="form.alumni.profession"/></label>
                                    <input type="text" className="form-control" name="profession" id="profession" required onChange={e => _handlePayload(e.target.value, 'profession')} value={payload.profession}/>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label><TextError/><FormattedMessage id="form.alumni.address"/></label>
                                    <textarea className="form-control" name="address" rows={5} data-rule="required" defaultValue={""} required onChange={e => _handlePayload(e.target.value, 'address')} value={payload.address} />
                                </div>  
                            </div>
                        }

                        onClose={() => _handleResetPayload()}
                        onDelete={() => deleteAlumni()}
                        disabled={loading}
                    />
                </form>

            </div>
        </div>
    )
}
