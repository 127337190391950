export default {
    // general
    'form.options': 'Options',
    'form.created_at': 'Created At',
    'form.number': 'No',
    'form.category': 'Category',
    'form.level': 'Level',
    // article
    'form.article.title': 'Title',
    'form.article.name': 'Name',
    'form.article.content': 'Content',
    'form.article.image': 'Image',
    'form.article.videoURL': 'Video URL',
    // event
    'form.event.start_at': 'Start At',
    'form.event.end_at': 'End At',
    'form.event.fee': 'Price',
    // category
    'form.category.name': 'Name',
    'form.category.color': 'Color',
    'form.category.color-picker': 'Select the Label Color',
    // user
    'form.user.activate': 'Aktifkan',
    'form.user.deactivate': 'Non aktifkan',
    'form.user.active': 'Aktif',
    'form.user.deactive': 'Non aktif',
    'form.user.role': 'Level',
    //alumni 
    'form.alumni.name': 'Name',
    'form.alumni.entry': 'Entry Year',
    'form.alumni.grad': 'Graduation Year',
    'form.alumni.department': 'Department',
    'form.alumni.profession': 'Profession',
    'form.alumni.address': 'Address',
    'form.alumni.action': 'Action',
    // admission
    'form.admission.title': 'Title (Bahasa)',
    'form.admission.en_title': 'Title (English)',
    'form.admission.content': 'Content (Bahasa)',
    'form.admission.en_content': 'Content (English)',
    'form.admission.image': 'Image',
    // vacancies
    'form.vacancies.title': 'Title (Bahasa)',
    'form.vacancies.en_title': 'Title (English)',
    'form.vacancies.content': 'Content (Bahasa)',
    'form.vacancies.en_content': 'Content (English)',
    'form.vacancies.image': 'Image',
    // calendar
    'form.calendar.title': 'Title',
    'form.calendar.content': 'Content',
    'form.calendar.image': 'Image',
    // achievment
    'form.achievment.title': 'Title',
    'form.achievment.content': 'Content',
    'form.achievment.image': 'Image',
    // testimonial
    'form.testimonial.name': 'Name Alumni',
    'form.testimonial.content': 'Content (Bahasa)',
    'form.testimonial.en_content': 'Content (English)',
    'form.testimonial.image': 'Image',  
    // teacher
    'form.teacher.name': 'Teacher Name',
    'form.teacher.position': 'Position (Bahasa)',
    'form.teacher.en_position': 'Position (English)'   
}