export default {
    'menu.dashboard': 'Dashboard',
    'menu.about': 'About Us',
    'menu.event': 'Event',
    'menu.article': 'School News',
    'menu.academic': 'Academic',
    'menu.admission': 'Admission',
    'menu.vacancies': 'Vacancies',
    'menu.testimonial': 'Testimonial',
    'menu.testimonials': 'Testimonials',
    'menu.academic-calendar': 'Academic Calendar',
    'menu.calendar': 'Calendar',
    'menu.achievment': 'Achievment',
    'menu.gallery': 'Gallery',
    'menu.category': 'Category',
    'menu.user': 'User',    
    'menu.alumni': 'Alumni',    
    'menu.language': 'Language',
    'menu.information': 'Information' ,     
    'menu.teacher': 'Teacher Profile',
    'menu.academic': 'Academic',
    'menu.non-academic': 'Non Academic',
    'menu.tour': 'Virtual School Tour',
    'menu.vision': 'Vision',
    'menu.mission': 'Mission',
    'menu.principal': 'Principal',
    'menu.chairman': 'Chairman',
    'menu.support': 'Support',
    'menu.activity-calendar': 'School Activity Calendar',      
    'menu.faq': 'Faq',
    'menu.inquiry': 'Inquiry',
    'menu.fees': 'Fees',
    'menu.apply': 'Apply',
    'menu.terms': 'Terms and Conditional',
    'menu.guidelines': 'Admission Guidelines',
    'menu.important': 'Important Dates',
    'menu.child': 'Child Safeguarding',
    'menu.policy': 'School Policy',
    'menu.license': 'School License',
    'menu.announcement': 'Announcement',
    'menu.school-management': 'School Management',
}