require('dotenv').config();
export default class AboutUs {
    
    constructor() {}

    list = async (params = {}) => {        
        const token = window.localStorage.getItem('token');
        
        params.page = parseInt(params.page) || 1;
        params.limit = params.limit || 10;
        params.lang = params.lang || '';

        const { page, limit, lang } = params;
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/aboutus/list?page=${page}&limit=${limit}&lang=${lang}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(res => {
                if (res.status === 401) {
                    window.location.href = '/';
                }
                res.json()
                .then(data => {
                    if(data.status && data.status.code === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        });
    }
    create = async (payload) => {
        const token = window.localStorage.getItem('token');

        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/aboutus/create`, {
                method: 'POST', 
                headers: {                    
                    'Authorization': `Bearer ${token}`
                },               
                body: payload
            })
            .then(res => {
                if (res.status === 401) {
                    window.location.href = '/';
                }
                res.json()
                .then(data => {
                    if(data.code === 201) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        });
    }    
}